.document-list {
	.ps {
		clear: both;
	}
	.document-item {
		&.headline {
			.documentLineWrap:hover {
				background: transparent;
			}
			.documentLineWrap {
				border-left-color: $light;
				* {
					color: $grey;
				}
			}
			.lineitem {
				> span + i {
					margin-left: 6px;
					color: $blue;
				}
				span {
					cursor: pointer;
					@include font-size(14);
				}
				span.active {
					color: $blue;
					font-weight: 500;
				}
			}
		}
		&.activeDocument {
			.documentLineWrap {
				border-bottom: 0;
				border-radius: 0;
				margin-bottom: 0;
				&:hover {
					background: $bgbox;
				}
				.lineitem {
				}
				.fa-x {
					display: none;
				}
			}
			> .card-body {
				border-top: 0;
				margin-bottom: 2px;
				> .card-header {
					border-radius: 0;
				}
				> .card-body {
					border-top: 0;
				}
			}
		}
		.documentLineWrap {
			margin-bottom: 0;
			transition: .5s all ease 0s;
			display: flex;
			align-items: center;
			border-bottom: 1px solid $border;
			width: 100%;
			border-left: 5px solid $grey1;
			&:hover {
				border-left-color: $grey;
			}
			&.invoiceorder, &.invoicefinal, &.invoice {
				border-left: 5px solid $red1;
				&:hover {
					border-left-color: $red;
				}
			}
			span.sepaMandat {
				color: $red;
			}
			&.angebot, &.offer {
				border-left: 5px solid $blue1;
				&:hover {
					border-left-color: $blue;
				}
			}
			&.order {
				border-left: 5px solid $green1;
				&:hover {
					border-left-color: $green;
				}
			}
			&.contract {
				border-left: 5px solid $orange1;
				&:hover {
					border-left-color: $orange;
				}
			}
			span.documentInfo {
				@include font-size(11, 1.5);
				display: block;
				font-weight: normal;
				white-space: pre;
				bottom: 0;
			}
			&:hover {
				background: $bgbox;
			}
			.lineitem {
				transition: .3s all ease 0s;
				padding-top: 8px;
				padding-bottom: 9px;
				padding-left: 6px;
				padding-right: 6px;
				min-width: 32px;
				flex-grow: 0;
				flex-shrink: 0;
				i.fa-duotone.fa-rectangle-xmark {
					color: $red;
				}
				&.l-checkbox {
					padding-left: 12px;
					padding-right: 6px;
					min-width: 51px;
				}
				&.l-title {
					width: 120px;
					text-overflow: ellipsis;
					overflow: hidden;
					font-weight: normal;
					@include font-size(14, 1.5);
					padding-right: 12px;
					cursor: pointer;
					.docinfo {
						line-height: 1.4;
						@media #{$xlarge}{
							display: block;
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
						}
					}
					.documentInfo {
						margin-top: 1px;
					}
					@media #{$hd}{
						width: 320px;
					}
					@media (min-width: 1910px) {
						width: 450px;
					}
				}
				&.l-avatar {
					text-align: center;
					padding-right: 12px;
					display: none;
					@media #{$xlarge}{
						display: block;
					}
				}
				&.l-number {
					padding-left: 12px;
					padding-right: 12px;
					cursor: pointer;
					width: 105px;
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;
					text-align: center;
					color: $grey;
					display: none;
					font-weight: normal;
					@include font-size(14);
					@media #{$hd}{
						display: block;
					}
				}
				&.l-company {
					width: 125px;
					text-overflow: ellipsis;
					overflow: hidden;
					white-space: nowrap;
					cursor: pointer;
					font-weight: 500;
					@include font-size(14, 1.8);
					max-width: 100%;
					padding-right: 12px;
					position: relative;
					@media #{$xlarge}{
						width: 245px
					}
				}
				&.l-category {
					width: 120px;
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;
					display: none;
					@media #{$hd}{
						display: block;
					}
				}
				&.l-date {
					@include font-size(14, 1.8);
					width: 80px;
					display: none;
					@media #{$xlarge}{
						display: block;
					}
				}
				&.l-netto, &.l-brutto {
					@include font-size(14, 1.8);
					width: 110px;
					text-align: right;
				}
				&.l-brutto {
					display: none;
					@media #{$hd}{
						display: block;
					}
				}
				&.l-email {
					width: 200px;
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;
					@include font-size(14, 1.8);
					display: none;
					@media #{$large}{
						display: block;
					}
					button {
						white-space: nowrap;
						text-overflow: ellipsis;;
						overflow: hidden;
						max-width: 100%;
					}
				}
				&.l-prio {
					padding-right: 24px;
					padding-left: 12px;
					display: none;
					@media #{$medium}{
						display: block;
					}
				}
				&.l-akquise {
					text-align: center;
				}
				&.l-actions {
					&.purple-actions{
						min-width: 120px;
						text-align: right;
					}
				}
				&.l-type {
					text-align: center;
					width: 70px;
					display: none;
					@media #{$medium}{
						display: block;
					}
					.badge {
						width: 100%;
						background: transparent;
						text-align: left;
						border: 0;
						padding-left: 0;
					}
				}
				&.last {
					padding-right: 16px;
					i {
						cursor: pointer;
					}
				}
				.btn-outline-secondary {
					color: $purple;
					&:hover {
						background: $purple;
						color: $light;
					}
				}
				.incNumber {
					@include font-size(11);
					position: absolute;
					z-index: 1;
					width: 16px;
					display: block;
					height: 16px;
					text-align: center;
					background: $purple2;
					line-height: 14px;
					border-radius: 100%;
					right: 0;
					top: 0;
					white-space: nowrap;
				}
				.btn:hover {
					.incNumber {
						background: $base;
						color: $light;
					}
				}
			}
		}
	}
	&.card-body > .document-item.activeDocument {
		box-shadow: 0px 3px 6px #00000029;
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 24px;
		.documentLineWrap {
			background: $bgbox;
			.fa-x {
				display: inline-block;
			}
		}
		> .card-body {
			@include clear();
			border-radius: 0 0 $br $br;
			border-top: 0;
			margin-bottom: 12px;
			.card {
				border: 0;
			}
		}
		.documentContent {
			> .card {
				border-radius: 0;
				> .card-header {
					border-radius: 0;
				}
			}
		}
	}
}

.documentItem {
	.mb-2 {
		margin-right: 15px;
	}
	.productList {
		margin-bottom: 24px;
		&.fixedList {
			position: fixed;
			background: $blue3;
			z-index: 100;
			right: 0;
			max-width: 40%;
			width: 40%;
			padding: 12px;
			@media #{$large}{
				padding: 24px;
			}
			box-shadow: none;
			transition: .3s transform ease 0s;
			transform: translateX(100%);
			button.btn-primary.btn.toggleBtn {
			}
			&.active {
				transform: translateX(0%);
				box-shadow: 0 0 10px -7px #333;
			}
			.searchHead {
				padding-bottom: 24px;
				.form-control {
					border: 1px solid $blue;
				}
				.text-primary {
					@include font-size(24);
					cursor: pointer;
				}
				.input-group-append {
					button {
						border: 1px solid $blue;
						color: $base;
					}
				}
			}
			.productItem {
				background: $light;
				margin-bottom: 6px;
				border-radius: $br 0 0 $br;
				&.highlighted {
					background: $blue2;
				}
				ul {
					li {
						display: inline-flex;
						align-items: center;
						@include font-size(14);
						&.arrow {
							background: $blue;
							border-radius: $br 0 0 $br;
							color: $light;
							justify-content: center;
							width: 10%;
							@media #{$xlarge}{
								width: 5%;
							}
						}
						&.title {
							width: 65%;
							padding-top: 6px;
							padding-bottom: 6px;
							@media #{$xlarge}{
								width: 50%;
							}
							span {
								white-space: nowrap;
								overflow: hidden;
								text-overflow: ellipsis;
								display: inline-block;
							}
						}
						&.quantity {
							width: 10%;
							display: none;
							@media #{$xlarge}{
								display: inline-flex;
							}
						}
						&.unit {
							width: 15%;
							display: none;
							@media #{$xlarge}{
								display: inline-flex;
							}
						}
						&.price {
							width: 25%;
							text-align: right;
							padding-left: 6px;
							@media #{$xlarge}{
								width: 15%;
							}
						}
					}
				}
			}
		}
		.resetButton {
			border: 1px solid #ddd;
			border-radius: 0 7px 7px 0;
		}
		.blocklabel {
			width: 100%;
			display: block;
		}
		.highlighted {
			background: $blue3;
		}
		.dragProducts {
		}
	}
}

.inlinePasswordEdit {
	@include clear();
	padding-top: 12px;
	padding-bottom: 12px;
}

.documentWrap {
	clear: both;
	padding-top: 6px;
	.document {
		padding-top: 12px;
		transition: .5s all ease 0s;
		padding-bottom: 9px;
		cursor: pointer;
		display: flex;
		align-items: center;
		border-bottom: 1px solid $border;
		&:hover, &.active {
			background: $bgbox;
		}
		.lineitem {
			transition: .3s all ease 0s;
			max-width: 100%;
			padding-left: 6px;
			padding-right: 6px;
			&.l-checkbox {
				padding-left: 12px;
				padding-top: 3px;
				padding-right: 6px;
			}
			&.l-avatar {
				text-align: center;
				padding-left: 32px;
				padding-right: 12px;
			}
			&.l-number {
				padding-left: 12px;
				padding-right: 12px;
				width: 105px;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
				text-align: center;
			}
			&.l-title {
				width: 320px;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
				font-weight: 500;
				@include font-size(14, 1.8);
				padding-right: 12px;
				&.l-doctitle {
					width: 440px;
				}
			}
			&.l-email {
				width: 200px;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
				@include font-size(14, 1.8);
				button {
					white-space: nowrap;
					text-overflow: ellipsis;;
					overflow: hidden;
					max-width: 100%;
				}
			}
			&.l-price {
				width: 100px;
				text-align: right;
				padding-right: 12px;
			}
			&.l-prio {
				padding-right: 24px;
				padding-left: 12px;
			}
			&.l-type {
				text-align: center;
				width: 70px;
				.badge {
					width: 100%;
				}
			}
			&.l-action {
				align-self: flex-end;
				padding-right: 24px;
				padding-left: 24px;
				text-align: center;
				.fa-x {
					display: inline-block;
				}
			}
			&.last {
				padding-right: 16px;
			}
		}
	}
}

span.roundBadge {
	display: inline-block;
	width: 24px;
	height: 24px;
	text-align: center;
	border-radius: 100%;
	font-weight: bold;
	color: #fff;
}

.infoWrapper {
	display: flex;
	gap: 12px;
	flex-wrap: wrap;
	max-width: 100%;
	.info {
		background: $bgbox;
		padding: 6px;
		.infoType {
			border-bottom: 1px solid $border;
			font-weight: 500;
		}
	}
}

.preInfoContent {
	white-space: pre;
}

.pageCounter {
	position: absolute;
	right: 12px;
	bottom: 22px;
}

.callNumber {
	color: $link;
	background: transparent;
	border: 0;
	margin-right: 12px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	max-width: 100%;
}

.btn-group {
	.callNumber {
		opacity: .3;
	}
	&:hover {
		.callNumber {
			opacity: 1;
		}
	}
}

.mainContact {
	a, button {
		@include font-size(14, 1.5);
		color: $base;
	}
	> .btn-group, > button.copyClip {
		@include clear();
		width: auto;
		text-align: left;
	}
}

.documentTabs {
	@include clear();
	@include font-size(14, 1.5);
}

.inDocument {
	.headbox {
		label {
			width: 100%;
			display: block;
		}
	}
}

.activityWrap {
	@include clear();
	.row {
		margin-left: 0;
		margin-right: 0;
		border-bottom: 1px solid $border;
		padding-bottom: 12px;
	}
	> .row {
		padding-bottom: 0;
	}
}

.documentContent {
	h6.card-header {
		display: flex;
		align-items: center;
		i {
			margin-right: 6px;
			margin-top: 2px;
		}
		> button, > .btn, .dropdown > .btn {
			margin-left: auto;
			margin-bottom: 0;
		}
		.btn-group {
			margin-left: auto;
		}
	}
}

.processTable {
	table tr {
		border-left: 5px solid $grey1;
		&.invoiceorder, &.invoicefinal, &.invoice {
			border-left: 5px solid $red1;
			&:hover {
				border-left-color: $red;
			}
		}
		&.angebot, &.offer {
			border-left: 5px solid $blue1;
			&:hover {
				border-left-color: $blue;
			}
		}
		&.order {
			border-left: 5px solid $green1;
			&:hover {
				border-left-color: $green;
			}
		}
		&.contract {
			border-left: 5px solid $orange1;
			&:hover {
				border-left-color: $orange;
			}
		}
	}
}
