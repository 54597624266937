
th, td {
  border-bottom-color: red;
}
.customerData .table-responsive td, .customerData .table-responsive th {
  font-size: 12px;
  text-align: left;
  /* max-width: 100%; */
  text-overflow: ellipsis;
}
.task {
  border-bottom: 5px solid #ffffff
}
.inline table.table {
  margin-bottom: 0px;
}

.inline table {
  width: 100%;
}

.complete table td, .inline table td {
  text-align: right;
  width: 24%;
  &:first-child {
    width: 52%;
    text-align: left;
  }
  &:last-child {
    color: #ccc;
  }
}
.customerData {
  background: #f7f7f7;
  padding: 6px;
  margin-bottom: 24px;
}
.profileImage {
  max-height: 200px;
  max-width: 100%;
}

table.profileData {
  float: left;
  max-width: 100%;
  width: 400px;
}
