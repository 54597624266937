#main-nav {
	width: 60px;
	height: 45px;
	position: relative;
	margin: 12px auto;
	transform: rotate(0deg);
	transition: .5s ease-in-out;
	cursor: pointer;
	display: block;
	@media #{$xlarge}{
		display: none;
	}
	span, &:before, &:after {
		display: block;
		position: absolute;
		height: 9px;
		width: 100%;
		background: $base;
		opacity: 1;
		left: 0;
		transform: rotate(0deg);
		transition: .25s ease-in-out;
	}
	&:before {
		top: 0px;
		transform: rotate(1deg);
		content: '';
		transform-origin: left center;
	}
	&:after {
		content: '';
		top: 18px;
		left: 4px;
		transform: rotate(-1deg);
		width: 85%;
		transform-origin: left center;
	}
	span {
		top: 36px;
		transform-origin: left center;
		width: 96%;
		transform: rotate(2deg);
	}
	&.open:before {
		transform: rotate(45deg);
		top: -3px;
		left: 8px;
	}
	&.open:after {
		width: 0;
		opacity: 0;
	}
	&.open span {
		transform: rotate(-45deg);
		top: 39px;
		left: 8px;
	}
	&.open {
		span, &:before, &:after {
			background: $base;
		}
	}
}

.navbarTop {
	margin: 0;
	background: $light;
	margin-bottom: 24px;
	padding: 0;
	flex-wrap: nowrap;
	justify-content: flex-start;
	position: relative;
	display: flex;
	align-items: center;
	padding-left: 24px !important;
	padding-right: 24px !important;
	@media #{$hd}{
		padding-left: 48px !important;
		padding-right: 48px !important;
	}
	padding-top: 12px;
	input#filter_search {
		max-width: 220px;
		height: 28px;
		@include font-size(14);
		line-height: 28px;
	}
	.input-group-append .btn {
		border-radius: 0;
		box-shadow: none;
		height: 32px;
		padding: 2px 9px;
		transform: none !important;
		border-right: 1px solid $blue;
		&:last-child {
			border-radius: 0 4px 4px 0;
			border-left: 0;
		}
	}
	span.ml-auto.btn.btn-small.btn-logout {
		white-space: nowrap;
	}
}

ul.navbar-nav {
	margin-left: 0;
	padding-left: 0;
	margin-right: 0;
	li.nav-item {
		> a {
			padding-left: 12px;
			@include font-size(14);
			&.router-link-active {
				background: #ddd;
			}
		}
		&:hover {
			background: #eee;
		}
		i {
			float: right;
			margin-right: 10px;
			margin-left: 12px;
			color: $blue;
			min-width: 32px;
			text-align: center;
			@include font-size(20, 1.2)
		}
		&.green {
			i {
				color: $green;
			}
		}
		&.pink {
			i {
				color: $purple;
			}
		}
		&.red {
			i {
				color: $red;
			}
		}
		&.orange {
			i {
				color: $orange;
			}
		}
	}
	li.nav-category {
		margin-right: 48px;
		margin-bottom: 12px;
		margin-left: 12px;
		width: auto;
	}
	li.filter-item {
		padding-right: 12px;
		padding-left: 12px;
		.multiselect {
			margin-bottom: 12px;
		}
		&.lead{
			font-size: 16px;
		}
		> input[type='text'] {
			margin-bottom: 12px;
			background: $light;
			border-color: $blue1;
			padding-left: 12px;
		}
		.input-group{
			input[type='number']{
				background: $light;
				border-color: $blue1;
				height: 30px;
				line-height: 30px;
				padding-left: 12px;
			}
		}
		.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback){
			height: 32px;
			border-top-color: $blue1;
			border-bottom-color: $blue1;
		}

	}
}

.nav-category {
	width: 100%;
	.category-link {
		padding-top: 0.5rem !important;
		padding-bottom: 0.5rem !important;
		margin: 0;
		display: flex;
		align-items: center;
		white-space: nowrap;
		text-transform: uppercase;
		margin-top: 24px;
		padding-left: 0;
		padding-right: 1rem;
		color: $base;
		font-size: 0.8rem;
		&.category-link-employees {
			border-bottom: .09rem solid $green;
		}
		&.category-link-workflows {
			border-bottom: .09rem solid $purple;
		}
		&.category-link-accounting {
			border-bottom: .09rem solid $red;
		}
		&.category-link-configuration {
			border-bottom: .09rem solid $orange;
		}
	}
}

aside {
	display: flex;
	min-height: 100vh;
	align-items: flex-start;
	box-shadow: $box-shadow-sm;
	flex-direction: column;
	&.sidenav {
		width: 227px;
		min-width: 227px;
		margin: 0 !important;
		z-index: 7;
		background: $bgbox;
		box-shadow: inset -1px 0px 12px -3px rgba(0, 0, 0, 0.2);
		position: fixed;
		.sidebar-header {
			align-self: flex-start;
		}
		&.closed {
			min-width: 60px;
			width: 60px;
			~ .main-content {
				margin-left: 60px;
			}
		}
		~ .main-content {
			margin-left: 227px;
		}
	}
	.sidenav-header {
		width: 100%;
		align-self: flex-start;
		padding: 1rem;
		padding-top: 3px;
		margin-bottom: 20px;
		position: relative;
	}
	.sidenav-footer.bottom-0 {
		align-self: flex-end;
		width: 100%;
		margin-left: 0 !important;
		margin-right: 0 !important;
	}
	div#sidenav-collapse-main {
		width: 100% !important;
	}
	.ps {
		max-height: 100%;
		height: 82vh;
	}
	&.navbar.closed {
		width: 60px;
		ul.navbar-nav {
			padding-left: 0;
			li {
				> a {
					padding-left: 0;
				}
				&.nav-item {
					text-align: center;
					padding-left: 0;
					i {
						margin-left: auto;
						margin-right: auto;
						float: none;
					}
					span.nav-link-text {
						display: none;
					}
				}
				&.nav-category {
					display: none;
				}
			}
		}
		.btn-toggler-sidebar {
			position: relative;
			i.fa-chevron-right {
				position: absolute;
				font-size: 12px;
				right: 4px;
				top: 10px;
			}
		}
	}
}

.topMainIcons {
	i {
		color: $blue;
		@include font-size(21, 1);
		cursor: pointer;
	}
	width: 100%;
	text-align: center;
	position: relative;
	.filterSaveIndicator{
		position: absolute;
		transform: translateX(10px);
		font-size: 14px;
		z-index: 12;
		top: 6px;
		i{font-size: 14px;}
	}
	.actionIcon {
		display: inline-block;
		width: 33.3333333333%;
		text-align: center;
		&.double {
			background: $brightblue;
			border-radius: 48px;
			width: auto;
			display: inline-block;
			box-shadow: inset 0 -2px 7px -5px #333;
			i {
				color: $light;
				@include font-size(16, 1);
				&:before, &:after {
					opacity: 1;
				}
			}
			span {
				&.active {
					background: $blue;
					border-radius: 100%;
					box-shadow: 0 0 7px -4px #333;
				}
				display: inline-block;
				width: 32px;
				height: 32px;
				line-height: 32px;
				transition: .3s all ease 0s;

			}
		}
		&.outter {
			position: absolute;
			right: 0;
			text-align: right;
			.btn {
				border-radius: 12px 0 0 12px;
				padding: 5px;
				i {
					font-size: 15px;
					color: $link;
					&:after, &:before {
						opacity: 1;
					}
				}
			}
		}
		.innerLast {
			position: relative;
			span.indicator {
				position: absolute;
				top: -4px;
				right: -4px;
				width: 16px;
				height: 16px;
				line-height: 16px;
				@include font-size(12);
				background: $light;
				border-radius: 100%;
				box-shadow: 0 0 7px -4px #333;
			}
		}
	}
}

ul.tabNav {
	margin: 0;
	padding: 0;
	width: 100%;
	display: flex;
	li {
		list-style: none;
		display: inline-block;
		padding: 8px 12px;
		flex-grow: 1;
		@include font-size(16);
		@media #{$large}{
			padding: 14px 24px;
		}
		text-align: center;
		cursor: pointer;
		border-bottom: 3px solid $blue1;
		small {
			text-transform: uppercase;
			font-weight: 600;
			display: none;
			@include font-size(13);
			@media #{$xlarge}{
				display: block;
			}
		}
		&.active, &:hover {
			border-bottom: 3px solid $blue;
			color: $blue;
		}
		&.active {
			small {
				font-weight: 700;
			}
		}
	}
	&.subtabs {
		border-bottom: 1px solid $bgbox;
		li {
			border-bottom: 0;
			padding: 12px;
			@include font-size(14);
			@media #{$large}{
				padding: 12px;
			}
			&.active, &:hover {
				background: $blue3;
			}
			&.active {
				font-weight: 600;
			}
		}
		~ p {
			margin-top: 24px;
			text-align: center;
			color: $placeholder;
			@include font-size(14);
		}
	}
}

.navScroller {
	overflow-y: scroll;
	overflow-x: hidden;
	height: 82vh;
	// Style scrollbar
	&::-webkit-scrollbar {
		width: 5px;
	}
	&::-webkit-scrollbar {
		background: $border;
		border-radius: $br $br $br $br;
	}

	&::-webkit-scrollbar-thumb {
		background-color: rgba(0, 0, 0, 0.2);
		border-radius: $br;
	}
	&::-webkit-scrollbar-corner{
		border-radius: $br;
	}

}
@-moz-document url-prefix() {
	.navScroller {
		scrollbar-width: thin;
	}
}
